import { createRouter } from "@swan-io/chicane";

const routes = {
  ProjectList: "/",
  PopupCallback: "/swanpopupcallback",
  Login: "/login?:sessionExpired",
  IdentificationCallback: "/identification-callback",
} as const;

export const Router = createRouter(routes);
