import { getLocation, replaceUnsafe } from "@swan-io/chicane";
import { useLayoutEffect } from "react";

type Props = {
  to: string;
};

export const Redirect = ({ to }: Props) => {
  useLayoutEffect(() => {
    const external = to.startsWith("http://") || to.startsWith("https://");

    if (external) {
      return window.location.replace(to);
    }

    const location = getLocation().toString();

    if (to !== location) {
      replaceUnsafe(to);
    }
  }, [to]);

  return null;
};
