import { BorderedIcon } from "@swan-io/lake/src/components/BorderedIcon";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { ScrollView } from "@swan-io/lake/src/components/ScrollView";
import { Space } from "@swan-io/lake/src/components/Space";
import { colors } from "@swan-io/lake/src/constants/design";
import { insets } from "@swan-io/lake/src/constants/insets";
import { StyleSheet } from "react-native";
import { t } from "../utils/i18n";

const styles = StyleSheet.create({
  contentContainer: {
    justifyContent: "center",
    marginHorizontal: "auto",
    maxWidth: "100%",
    minHeight: "100%",
    paddingLeft: insets.addToLeft(80),
    paddingRight: insets.addToRight(80),
    width: 560,
    paddingVertical: 56,
  },
});

export const IdentificationCallbackPage = () => (
  <ScrollView role="main" contentContainerStyle={styles.contentContainer}>
    <Box alignItems="center">
      <BorderedIcon color="positive" name="lake-check" padding={24} size={100} />
      <Space height={24} />

      <LakeHeading level={1} variant="h1" align="center">
        {t("identification.callback.title")}
      </LakeHeading>

      <Space height={8} />

      <LakeText align="center" color={colors.gray[900]}>
        {t("identification.callback.description")}
      </LakeText>
    </Box>
  </ScrollView>
);
